<script setup lang="ts">
const router = useRouter();

// Redirect to the index route so automatic browser language detection works.
onMounted(async () => {
  await router.replace('/');
  // We have to hard reload the page to trigger the automatic browser language detection from i18n module,
  // otherwise it would only redirect to "/" without running the browser language detection
  reloadNuxtApp();
});
</script>

<template>
</template>
